import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import Controls from "./controls/Controls";
import { API } from "../commons/commonMethods";
import { useForm, Form } from "./useForm";
import "../assets/css/styles.css";
import "./css/Comment.css";

const initialFValues = {
  comment: "",
  name: "",
  email: "",
};

function Comment({ comments, type, id, setNewComment }) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [comments]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("email" in fieldValues)
      temp.email = /$^|.+@.+..+/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      setIsLoading(true);

      API()
        .post("/comment", {
          id: id,
          type: type,
          ...values,
          date: JSON.stringify(new Date()).replace(/\"/g, ""),
        })
        .then((res) => {
          console.log("message => ", res.data.message);
          setNewComment(values);
          setIsLoading(false);
          resetForm();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          resetForm();
        });
      let templateParams = {
        id: id,
        type: type,
        values: values.name,
        message: values.comment,
        to_name: "Turbold",
        comment_email: values.email,
      };
      emailjs
        .send(
          "service_5lkw6jb",
          "template_u8vhn1q",
          templateParams,
          "user_8Su9Rqw34eBKZTERYgCJP"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    }
  };

  const commentList = (list) => {
    return (
      list &&
      list.map((comment, index) => {
        return (
          <div className="comment" key={index}>
            <div className="comment-header">
              <div className="name">{comment.name}</div>
              <div className="date">
                {new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                  hour: "numeric",
                  minute: "numeric",
                }).format(comment.date ? new Date(comment.date) : new Date())}
              </div>
            </div>
            <div className="comment-body">{comment.comment}</div>
          </div>
        );
      })
    );
  };

  return (
    <div className="comment-main">
      <div className="comments">{commentList(comments)}</div>
      <Form onSubmit={handleSubmit}>
        <Controls.TextArea
          label="COMMENT"
          name="comment"
          value={values.comment}
          onChange={handleInputChange}
        />
        <Controls.Input
          label="NAME"
          name="name"
          value={values.name}
          onChange={handleInputChange}
          error={errors.name}
        />
        <Controls.Input
          label="EMAIL"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
        />
        <div>
          <Controls.Button
            className="submit-post"
            type="submit"
            text="POST COMMENT"
          />
        </div>
      </Form>
    </div>
  );
}

export default Comment;
