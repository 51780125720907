import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderTab from "../components/Header";
import { Box } from "@material-ui/core";
import { API } from "../commons/commonMethods";
import FooterMain from "../components/Footer";
import Content from "../components/Content";
import "../assets/css/styles.css";
import "./css/Profile.css";

export default function Profile() {
  const { id } = useParams();
  const [profile, setProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    setIsLoading(true);
    API()
      .get(`/ourPeople/${id}`)
      .then((res) => {
        setProfile(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <Box display="flex">
          <div className="container main-box profile">
            {isLoading ? (
              <div>Loading ...</div>
            ) : (
              Object.keys(profile).length > 0 && (
                <Box className="service-flex">
                  <Box className="profile-side">
                    <img
                      src={require(`../assets/images/our-people/${profile.imagePath}`)}
                      alt={profile.name}
                    />
                  </Box>
                  <Box className="profile-content">
                    <div className="title">{profile.name}</div>
                    <Content content={profile.content} />
                  </Box>
                </Box>
              )
            )}
          </div>
        </Box>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
