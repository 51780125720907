import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { contents } from '../components/Services'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import '../assets/css/styles.css'
import './css/Operations.css'

const content = [
  {
    title: 'Management \n Operating System \n (MOS)',
    textTitle:'Management \n Operating System \n (MOS)',
    text:
      'Aligning an operation to a common set of goals, and an optimal execution plan is a complex undertaking. Typically businesses let each department act in silos decisions are often made in the absence of data. The business has difficulty in identifying improvement opportunities and when they do those Improvement initiatives often fail to deliver to intended outcomes.',
  },
  {
    title: 'Operational \n Information & Data \n Management',
    textTitle:'Operational \n Information & Data \n Management',
    text:
      'Most of the operational information is locked within documents and drawings which are both difficult to find and maintain. Existing organisational processes to manage information are costly and also typically not effective in improving quality of the organisation information set. The quality of the information has significant process safety risks, as well as productivity challenges when carrying out maintenance tasks.',
  },
  {
    title: 'Condition \n Monitoring & \n Reliability',
    textTitle:'Condition \n Monitoring & \n Reliability',
    text:
      'Industrial operations rely on their physical assets to produce revenue; optimal management of these assets over their lifetime can materially influence the performance of the overall operation. Most owners maintain assets based on a predefined schedule and consequently many assets are over maintained, which in itself can further increase the risk for operational change management.',
  },
  {
    title: 'Automation, \n Simulation & \n Optimisation',
    textTitle: 'Automation, \n Simulation & \n Optimisation',
    text:
      'Data underpins quantifiable ongoing improvement. It can be used to answer constant improvement questions right across the business. These can include: -How can we increase yield, throughput and revenue? -Do existing supply chains execute effectively? Are you getting value for money from your procurement? How efficient is our maintenance program? Reactive & Planned, or Intelligent & Predictive?',
  },
]

export default function Operations() {
  const [clickIndex, setClickIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box">
          <div className="operations-home">
            <div className="container">
              <div className="description">
                Improving Operational Performance
              </div>
            </div>
            <Box className="service-flex">
              {contents(content, clickIndex, setClickIndex)}
            </Box>
          </div>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
