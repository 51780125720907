import React from 'react'
import { TextField } from '@material-ui/core'

export default function TextArea(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    variant = 'outlined',
    multiline = true,
    rows = 4,
    placeholder,
    fullWidth,
  } = props
  return (
    <TextField
      variant={variant}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      multiline={multiline}
      rows={rows}
      placeholder={placeholder}
      fullWidth={fullWidth}
      {...(error && { error: true, helperText: error })}
    />
  )
}
