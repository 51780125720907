import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { contents } from '../components/Services'
import HeaderTab from '../components/Header'
import FooterMain from '../components/Footer'
import '../assets/css/styles.css'
import './css/CapitalProjects.css'

const content = [
  {
    title: 'Advanced Work \n Packaging (AWP)',
    textTitle:'Advanced Work Packaging (AWP)',
    text:
      'Improving productivity on projects is difficult.Ultimately poor productivity is realised in the field in terms of material shorts, downtime, rework, together accounting for upwards of 25% extra to the project capital cost. Advanced Work Packaging (AWP) is a set of integrated work processes that can successfully combine Engineering, Procurement and Construction across the supply chain to materially increases productivity by upwards of 10%.',

    },
  {
    title: 'Project Execution \n Systems \n Establishment',
    textTitle:'Project Execution Systems Establishment',
    text:
      'The information systems supporting projects are split across multiple organisations and information flow between systems is usually inadequate. Investment in project execution systems are an investment in the productivity of the project teams.',
  },
  {
    title: 'Building \n Information \n Modelling (BIM)',
    textTitle:'Building Information Modelling (BIM)',
    text:
      'Major projects have complex supply chains made up of teams from multiple companies, all requiring access, and contributing to a common set of project information.The difficulty in bringing together this supply chain as a single virtual team can be a major contributor towards poor project productivity. Building Information Modelling (BIM) focuses on integrating the supply chain to provide a single, reliable common source of data that can be used on a project',
  },
  {
    title: 'Scoping & Estimating \n Capital Project \n Technology',
    textTitle:'Scoping & Estimating Capital Project Technology',
    text:
      ' Most operations now have a significant, and growing reliance on systems and technology. However, projects often fail to recognise the required investment in their budget. Most projects now spend a combined technology budget of 2%-4% of total project capital across a range of technology and systems.',

      },
]

export default function CapitalProjects() {
  const [clickIndex, setClickIndex] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />
        <div className="container main-box">
          <div className="capital-projects-home">
            <div className="container">
              <div className="description">
                Reliable Capital Project Execution
              </div>
            </div>
            <Box className="service-flex">
              {contents(content, clickIndex, setClickIndex)}
            </Box>
          </div>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  )
}
