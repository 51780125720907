import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../commons/commonMethods";
import { contentType } from "../commons/enum";
import "../assets/css/styles.css";
import "./css/AllBlogs.css";
import HeaderTab from "../components/Header";
import FooterMain from "../components/Footer";

export default function AllBlogs() {
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      API()
        .get(`/${contentType.BLOG}`)
        .then((res) => {
          setBlogs(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const blogList = (list) => {
    return list.map((item, index) => {
      return (
        <Link to={`/blog/${item.id}`} key={index}>
          <article className="grid-element">
            <div className="content">
              <img
                src={
                  require(`../assets/images/blogs/${item.imagePath}`).default
                }
                alt={item.title}
              />
            </div>
            <section className="title">{item.title}</section>
          </article>
        </Link>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="background">
        <HeaderTab />

        <div className="container main-box all-blogs">
          <div className="grid-container">
            {isLoading ? <div>Loading ...</div> : blogList(blogs)}
          </div>
        </div>
        <FooterMain />
      </div>
    </React.Fragment>
  );
}
