import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import Controls from "./controls/Controls";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import { useForm, Form } from "./useForm";
import NestedMenuItem from "material-ui-nested-menu-item";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ListItemText from "@material-ui/core/ListItemText";

import logo from "../assets/images/Logo.png";
import { makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";

import "../assets/css/styles.css";
import "./css/Header.css";
import { useMsal } from "@azure/msal-react";
import { BrowserUtils } from "@azure/msal-browser";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#273e5e",
      color: "white",
    },
  },
  nested: {
    "& .MuiPaper-root": {
      backgroundColor: "#1C2E46",
      color: "white",
    },
  },
});

export default function Header() {
  const iPadWidth = useMediaQuery("(min-width:750px)");
  const classes = useStyles();
  const { instance } = useMsal();

  const handleSignout = () => {
    if (instance) {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    }
  };

  let history = useHistory();
  const [smOpen, setSmOpen] = useState(null);
  const [cmOpen, setCmOpen] = useState(null);
  const [drawer, setDrawer] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [openCollapse1, setOpenCollapse1] = React.useState(false);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }
  function handleOpenSettings1() {
    setOpenCollapse1(!openCollapse1);
  }

  const handleClick = () => {
    setOpen(!open);
  };

  const [menuPosition, setMenuPosition] = useState(null);

  const handleleftClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };
  const handleItemClick = () => {
    setMenuPosition(null);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("keyword" in fieldValues) {
      temp.keyword = fieldValues.keyword
        ? ""
        : "Please enter search keyword first.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    { keyword: "" },
    true,
    validate
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      history.push(`/search-group?keyword=${values.keyword}`);
      resetForm();
    }
  };

  const servicesMenu = () => {
    return (
      <Paper className="MuiMenu-paper">
        <Menu
          anchorEl={smOpen}
          className={classes.root}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={Boolean(smOpen)}
          onClose={() => {
            setSmOpen(null);
          }}
        >
          <NestedMenuItem
            className={classes.root}
            label="Reliable Capital Project Execution"
            parentMenuOpen={Boolean(smOpen)}
            onClick={handleItemClick}

            // onClick={() => {
            //   history.push('/capitalProjects');
            // }}
          >
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/capitalProjects");
              }}
            >
              Advanced Work Packaging (AWP)
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/capitalProjects");
              }}
            >
              Project Execution Systems Establishment
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/capitalProjects");
              }}
            >
              Building Information Modelling (BIM)
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/capitalProjects");
              }}
            >
              Scoping & Estimating Capital Project Technology
            </MenuItem>
          </NestedMenuItem>
          <Divider />
          <NestedMenuItem
            className={classes.root}
            label="Smooth Operational Readiness"
            parentMenuOpen={Boolean(smOpen)}
            onClick={handleItemClick}

            // onClick={() => {
            //   history.push('/operationalReadiness');
            // }}
          >
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operationalReadiness");
              }}
            >
              Technology Scoping & Estimation
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operationalReadiness");
              }}
            >
              Asset Management Systems Readiness
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operationalReadiness");
              }}
            >
              Integrated Technology Design
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operationalReadiness");
              }}
            >
              Operational Technology Implementation
            </MenuItem>
          </NestedMenuItem>
          <Divider />
          <NestedMenuItem
            className={classes.root}
            label="Improving Operational Performance"
            parentMenuOpen={Boolean(smOpen)}
            onClick={handleItemClick}
            // onClick={() => {
            //   history.push('/operations');
            // }}
          >
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operations");
              }}
            >
              Management Operating System (MOS)
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operations");
              }}
            >
              Operational Information & Data Management
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operations");
              }}
            >
              Condition Monitoring & Reliability
            </MenuItem>
            <MenuItem
              className={classes.root}
              onClick={() => {
                history.push("/operations");
              }}
            >
              Automation, Simulation & Optimisation
            </MenuItem>
          </NestedMenuItem>
        </Menu>
      </Paper>
    );
  };

  const contentMenu = () => {
    return (
      <Menu
        anchorEl={cmOpen}
        getContentAnchorEl={null}
        className={classes.root}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(cmOpen)}
        onClose={() => {
          setCmOpen(null);
        }}
      >
        <MenuItem
          onClick={() => {
            history.push("/allVideos");
          }}
        >
          Videos
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/allBlogs");
          }}
        >
          Blogs
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            history.push("/allPodcasts");
          }}
        >
          Podcasts
        </MenuItem>
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <SwipeableDrawer
        className="main-drawer"
        anchor="right"
        open={drawer}
        onOpen={() => setDrawer(true)}
        onClose={() => setDrawer(false)}
      >
        <Paper className="main-drawer-paper" elevation={0}>
          <a id="home" className="menu-item" href="/">
            Home
          </a>
          <a id="home" className="menu-item" href="/ourPeople">
            Our People
          </a>
          <a id="home" className="menu-item" href="/contactUs">
            Contact Us
          </a>
          {/* <a id="home" className="menu-item" href="/careers">
            Careers
          </a> */}
          <Divider />

          <a id="home" className="menu-item" button onClick={handleClick}>
            Reliable Capital Project Execution
            {open ? <ExpandLess /> : <ExpandMore />}
            <Collapse
              className="menu-item"
              in={open}
              timeout="auto"
              unmountOnExit
            >
              <List className="menu-item" component="div" disablePadding>
                <ListItem
                  button
                  parentMenuOpen={Boolean(open)}
                  className="menu-item"
                  onClick={() => {
                    history.push("/capitalProjects");
                  }}
                >
                  <ListItemText
                    className="menu-item"
                    primary="Advanced Work Packaging (AWP)"
                  />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/capitalProjects");
                  }}
                >
                  <ListItemText primary="Project Execution Systems Establishment" />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/capitalProjects");
                  }}
                >
                  <ListItemText
                    className="menu-item"
                    primary="Building Information Modelling (BIM)"
                  />
                </ListItem>
              </List>
            </Collapse>
            <Collapse
              className="menu-item"
              in={open}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/capitalProjects");
                  }}
                >
                  <ListItemText primary="Scoping & Estimating Capital Project Technology" />
                </ListItem>
              </List>
            </Collapse>
          </a>
          <a
            id="home"
            className="menu-item"
            button
            onClick={handleOpenSettings}
          >
            Smooth Operational Readiness
            {openCollapse ? <ExpandLess /> : <ExpandMore />}
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operationalReadiness");
                  }}
                >
                  <ListItemText primary="Technology Scoping & Estimation" />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operationalReadiness");
                  }}
                >
                  <ListItemText primary="Asset Management Systems Readiness" />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operationalReadiness");
                  }}
                >
                  <ListItemText
                    className="menu-item"
                    primary="Integrated Technology Design"
                  />
                </ListItem>
              </List>
            </Collapse>
            <Collapse
              className="menu-item"
              in={openCollapse}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operationalReadiness");
                  }}
                >
                  <ListItemText primary="Operational Technology Implementation" />
                </ListItem>
              </List>
            </Collapse>
          </a>
          <a
            id="home"
            className="menu-item"
            button
            onClick={handleOpenSettings1}
          >
            Improving Operational Performance
            {openCollapse1 ? <ExpandLess /> : <ExpandMore />}
            <Collapse in={openCollapse1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operations");
                  }}
                >
                  <ListItemText primary="Management Operating System (MOS)" />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={openCollapse1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operations");
                  }}
                >
                  <ListItemText primary="Operational Information & Data Management" />
                </ListItem>
              </List>
            </Collapse>
            <Collapse in={openCollapse1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operations");
                  }}
                >
                  <ListItemText
                    className="menu-item"
                    primary="Condition Monitoring & Reliability"
                  />
                </ListItem>
              </List>
            </Collapse>
            <Collapse
              className="menu-item"
              in={openCollapse1}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem
                  button
                  className="menu-item"
                  onClick={() => {
                    history.push("/operations");
                  }}
                >
                  <ListItemText primary="Automation, Simulation & Optimisation" />
                </ListItem>
              </List>
            </Collapse>
          </a>

          <Divider />
          <a id="about" className="menu-item" href="/allVideos">
            Videos
          </a>
          <a id="contact" className="menu-item" href="/allBlogs">
            Blogs
          </a>
          <a id="contact" className="menu-item" href="/allPodcasts">
            Podcasts
          </a>
          <Divider />
          <a id="admin" className="menu-item" href="/admin">
            Admin
          </a>
          {instance.getActiveAccount() == null ? null : (
            <>
              <Divider />
              <MenuItem
                style={{ color: "white", fontSize: "1.1em", width: "100%" }}
                onClick={handleSignout}
              >
                <div style={{ textAlign: "center", width: "100%" }}>
                  Sign out
                </div>
              </MenuItem>
            </>
          )}
        </Paper>
      </SwipeableDrawer>
      <Box className="main-header">
        <Box className="header-logo">
          <a id="homeButton" href="/">
            <img src={logo} alt="cravern logo" />
          </a>
        </Box>
        <Box className="header-content">
          <div className="global-search">
            <Form className="search-form" onSubmit={handleSubmit}>
              <Controls.Input
                name="keyword"
                className="hidden-input"
                value={values.keyword}
                onChange={handleInputChange}
                error={errors.keyword}
              />
              <Controls.Button
                type="submit"
                startIcon={<SearchIcon />}
                className="search-button"
              />
            </Form>
            <IconButton
              className="main-drawer-button"
              onClick={() => setDrawer(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          </div>
          {iPadWidth && (
            <div className="navigation">
              <Link className="navigation-item" to={"/"}>
                Home
              </Link>
              <Link to={"/ourPeople"} className="navigation-item">
                Our People
              </Link>
              <div
                className="navigation-item"
                onClick={(event) => {
                  setSmOpen(event.currentTarget);
                }}
              >
                Our Services <ExpandMore />
              </div>
              {servicesMenu()}
              <div
                className="navigation-item"
                onClick={(event) => {
                  setCmOpen(event.currentTarget);
                }}
              >
                Our Content <ExpandMore />
              </div>
              {contentMenu()}
              <Link to={"/careers"} className="navigation-item">
                Careers
              </Link>
              <Link to={"/contactUs"} className="navigation-item">
                Contact Us
              </Link>
              {contentMenu()}
            </div>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}
