import React from 'react'
import { TextField } from '@material-ui/core'

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    variant = 'outlined',
    className,
    placeholder,
    fullWidth,
    size = 'medium',
  } = props
  return (
    <TextField
      variant={variant}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      fullWidth={fullWidth}
      size={size}
      {...(error && { error: true, helperText: error })}
    />
  )
}
