import React, { useState, useEffect } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Controls from './controls/Controls'
import { useForm, Form } from './useForm'
import { API } from '../commons/commonMethods'
import '../assets/css/styles.css'
import './css/SearchHeader.css'

const initialFValues = {
  keyword: '',
}

export default function SearchHeader({
  keyword,
  type,
  setResult,
  contentType,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [checkValue, setCheckValue] = useState({
    video: true,
    blog: true,
    podcast: true,
    career: true,
    ourPeople: true,
  })
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    if (contentType) {
      let value = {
        video: false,
        blog: false,
        podcast: false,
        career: false,
        ourPeople: false,
      }
      value[contentType] = true
      setCheckValue(value)
    }

    values.keyword = keyword ? keyword : ''
  }, [])

  const validate = (fieldValues = values) => {
    let temp = { ...errors }
    if ('keyword' in fieldValues)
      temp.keyword = fieldValues.keyword
        ? ''
        : 'Please enter search text first.'
    setErrors({
      ...temp,
    })

    if (fieldValues == values) return Object.values(temp).every((x) => x == '')
  }

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialFValues,
    true,
    validate,
  )

  const getCheckedList = (value) => {
    let checked = []
    Object.keys(value).map((key) => value[key] && checked.push(key))
    return checked
  }

  const handleChange = (event) => {
    let value = { ...checkValue }
    value[event.target.name] = event.target.checked
    const checked = getCheckedList(value)
    setIndeterminate(
      !!checked.length && checked.length < Object.keys(value).length,
    )
    setCheckAll(checked.length === Object.keys(value).length)
    setCheckValue(value)
  }

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setCheckValue({
        video: true,
        blog: true,
        podcast: true,
        career: true,
        ourPeople: true,
      })
    } else {
      setCheckValue({
        video: false,
        blog: false,
        podcast: false,
        career: false,
        ourPeople: false,
      })
    }
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      setIsLoading(true)
      values['areas'] = getCheckedList(checkValue)
      API()
        .post('/search', values)
        .then((res) => {
          setResult(res.data)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
        })
    }
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <React.Fragment>
          <div className="top">SEARCH RESULT</div>
          <Form onSubmit={handleSubmit}>
            {type === 'group' && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkAll}
                      onChange={onCheckAllChange}
                      indeterminate={indeterminate}
                      color="primary"
                    />
                  }
                  label="All"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkValue.video}
                      name="video"
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Video"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkValue.blog}
                      name="blog"
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Blog"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkValue.podcast}
                      name="podcast"
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Podcast"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkValue.career}
                      name="career"
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Career"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkValue.ourPeople}
                      name="ourPeople"
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Our People"
                />
              </FormGroup>
            )}
            <Controls.Input
              label="Search"
              name="keyword"
              value={values.keyword}
              onChange={handleInputChange}
              fullWidth={false}
              size="small"
              error={errors.keyword}
            />
            <div>
              <Controls.Button
                className="submit-post"
                type="submit"
                text="Search"
              />
            </div>
          </Form>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
