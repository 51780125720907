import Input from './Input'
import RadioGroup from './RadioGroup'
import Select from './Select'
import Checkbox from './Checkbox'
import Button from './Button'
import TextArea from './TextArea'

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  Button,
  TextArea,
}

export default Controls
